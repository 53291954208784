import React, { useState } from 'react';
import {
	Box,
	Card,
	CardContent,
	Typography,
	IconButton,
	TextField,
	Grid,
	Divider
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import OrderStatusIndicator from "./OrderStatusIndicator";
import ConfirmationDialog from "./confirmationDialog";
import Notification from "./Notification";
import { createDispatchNote } from "../api/apiFunctions";

const Cart = ({ cart, setCart, handleRemoveFromCart, totalPriceEUR, handleNoteChange, note, confirmOpen, handleCloseConfirm, orderSuccess, style, showNotification, setConfirmOpen }) => {
	const [notificationOpen, setNotificationOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [notificationMessage, setNotificationMessage] = useState("");
	const [notificationSeverity, setNotificationSeverity] = useState("info");

	const handleOpenConfirmModified = () => {
		if (cart.length === 0) {
			showNotification('Vaša košarica je prazna!', 'warning');
			return;
		}
		setConfirmOpen(true);
	};

	const handleConfirmOrder = async () => {
		if (cart.length === 0) {
			setNotificationMessage("Vaša košarica je prazna!");
			setNotificationSeverity("warning");
			setNotificationOpen(true);
			return;
		}

		const cartData = JSON.parse(localStorage.getItem('eko-jazo-cart')) || [];
		const formattedData = {
			type: 'wholesale',
			items: cartData.map(item => ({
				itemId: item.id,
				quantity: item.quantity,
				discount: item.discount || 0
			}))
		};

		try {
			setLoading(true);
			await createDispatchNote(formattedData);

			setCart([]);
			localStorage.removeItem('eko-jazo-cart');
			setNotificationMessage("Narudžba je uspješno kreirana!");
			setNotificationSeverity("success");
			setNotificationOpen(true);

			setTimeout(() => {
				setNotificationOpen(false);
			}, 3000);
		} catch (error) {
			console.error('Order failed:', error);
			setNotificationMessage("Greška prilikom kreiranja narudžbe.");
			setNotificationSeverity("error");
			setNotificationOpen(true);
		} finally {
			setLoading(false);
			setConfirmOpen(false);
		}
	};

	const handleCloseNotification = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setNotificationOpen(false);
	};

	return (
		<div
			style={{
				position: 'sticky',
				top: '80px',
				zIndex: 1000
			}}
		>
			<Notification
				open={notificationOpen}
				handleClose={handleCloseNotification}
				message={notificationMessage}
				severity={notificationSeverity}
			/>
			<Card
				sx={{
					margin: "16px 0",
					position: { md: "sticky", xs: "relative" },
					top: 80,
					backgroundColor: "#F5F7FD",
					opacity: 0.8,
				}}
			>
				<CardContent
					sx={{
						maxHeight: 'calc(100vh - 150px)',
						position: 'relative',
						overflowY: 'auto',
						'&::-webkit-scrollbar': {
							width: '0.4em'
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: 'rgba(0,0,0,.1)',
							outline: '1px solid slategrey'
						}
					}}
				>
					<Typography variant="h5" align="left" sx={{ color: "#000000", fontSize: '1.8rem' }}>
						<ShoppingCartIcon sx={{ marginRight: 1 }} />
						Košarica
					</Typography>
					{cart.map((product, index) => (
						<React.Fragment key={product.id}>
							<Grid container alignItems="center" justifyContent="center" sx={{ marginBottom: '8px' }}>
								<Grid item xs={6.5}>
									<Typography variant="body1" align={"left"} sx={{ color: "#307AFF" }}>
										{product.name}
									</Typography>
								</Grid>
								<Grid item xs={1.5}>
									<Typography variant="body1" sx={{ color: "#307AFF" }}>
										{product.measure}
									</Typography>
								</Grid>
								<Grid item xs={2}>
									<Typography variant="body2" align="right">x{product.quantity}</Typography>
								</Grid>
								<Grid item xs={2}>
									<IconButton onClick={() => handleRemoveFromCart(product.id)} sx={{ float: 'right' }}>
										<DeleteIcon />
									</IconButton>
								</Grid>
							</Grid>
							{index !== cart.length - 1 && <Divider />}
						</React.Fragment>
					))}
					<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Typography variant="h6" sx={{ fontWeight: "bold" }}>
							Ukupna cijena (EUR):
						</Typography>
						<Typography variant="h6">{totalPriceEUR}</Typography>
					</Box>
					<TextField
						label="Napomena"
						value={note}
						onChange={handleNoteChange}
						multiline
						rows={4}
						fullWidth
					/>
					<ConfirmationDialog
						buttonTitle="Naruči"
						modalTitle="Potvrda narudžbe"
						modalContent="Jeste li sigurni da želite izvršiti narudžbu?"
						confirmText="Potvrdi"
						cancelText="Odustani"
						open={confirmOpen}
						onOpen={handleOpenConfirmModified}
						onCancel={handleCloseConfirm}
						onConfirm={handleConfirmOrder}
						setConfirmOpen={setConfirmOpen}
					/>
					<OrderStatusIndicator
						loading={loading}
						orderSuccess={orderSuccess}
						style={style}
					/>
				</CardContent>
			</Card>
		</div>
	);
};

export default Cart;
