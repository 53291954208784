import React from 'react';
import { Grid, Card, CardContent, Typography } from "@mui/material";

const ProductTableHeader = () => {
	return (
		<Card sx={{ margin: "16px 0", backgroundColor: "#F5F7FD", opacity: 0.8 }}>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={2}>
						<Typography variant="h6" fontWeight={"bold"}>#</Typography>
					</Grid>
					<Grid item xs={3}>
						<Typography variant="h6" fontWeight={"bold"}>Proizvod</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography variant="h6" fontWeight={"bold"}>Mjera</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography variant="h6" fontWeight={"bold"}>Cijena €</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography variant="h6" fontWeight={"bold"}>Količina</Typography>
					</Grid>
					<Grid item xs={1}>
						<Typography variant="h6" fontWeight={"bold"}>Dodaj</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default ProductTableHeader;
