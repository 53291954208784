import React, { useState, forwardRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmationDialog({ onConfirm, onCancel, header, buttonTitle, modalTitle, modalContent, confirmText, cancelText}) {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		onCancel();
	};

	const handleConfirm = () => {
		setOpen(false);
		onConfirm();
	};

	return (
		<React.Fragment>
			<Button variant="contained" color="primary" onClick={handleClickOpen}>
				{buttonTitle}
			</Button>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="confirmation-dialog-title"
				aria-describedby="confirmation-dialog-description"
			>
				<DialogTitle id="confirmation-dialog-title">{modalTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText id="confirmation-dialog-description">
						{modalContent}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{cancelText}</Button>
					<Button onClick={handleConfirm} color="primary">
						{confirmText}
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}

export default ConfirmationDialog;
