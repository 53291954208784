import React from 'react';
import {
	Box,
	Typography,
	IconButton,
	Slide
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const Header = ({ customerData, handleLogout, trigger }) => {
	return (
		<Slide appear={false} direction="down" in={!trigger}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: "#F5F7FD",
					opacity: 0.8,
					padding: "16px 24px",
					position: "sticky",
					top: 0,
					zIndex: 1000,
				}}
			>
				<Box sx={{ flexGrow: 1 }}>
					<Typography variant="body1" align="center" sx={{ fontSize: '1.2rem' }}>
						Dobrodošli, {customerData ? customerData.companyName : 'Klijent'}
					</Typography>
				</Box>
				<Box sx={{ flexGrow: 1, textAlign: "left" }}>
					<Typography variant="h6">Podružnica:</Typography>
					<Typography variant="body1">
						Adresa: {customerData ? customerData.branches[0].branchAddress : 'Adresa'}<br/>
						Mjesto: {customerData ? customerData.branches[0].branchZip : 'Zip'} {customerData ? customerData.branches[0].branchCity : 'Grad'}<br/>
						OIB: {customerData ? customerData.companyVat : 'OIB'}
					</Typography>
				</Box>
				<IconButton onClick={handleLogout} sx={{ color: 'red', width: '2rem', height: '2rem' }}>
					<ExitToAppIcon />
				</IconButton>
			</Box>
		</Slide>
	);
}

export default Header;