import {apiClient} from "./api";

async function fetchProducts() {
	const response = await apiClient.get('/wholesale/items');
	console.log(response.data.items);
	return response.data.items;
}

async function fetchCustomerData() {
	const response = await apiClient.get('wholesale/customer');
	return response.data;
}

async function createDispatchNote(orderDetails) {
	const response = await apiClient.post('/dispatch-note', orderDetails);
	return response.data;
}

export {fetchProducts, fetchCustomerData, createDispatchNote};
