import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Notification = ({ open, handleClose, message, severity }) => {
	return (
		<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
			<MuiAlert onClose={handleClose} severity={severity} elevation={6} variant="filled">
				{message}
			</MuiAlert>
		</Snackbar>
	);
}

export default Notification;