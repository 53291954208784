import React, { useState, useEffect } from "react";
import {Container, Grid, useScrollTrigger} from "@mui/material";
import './style/wholesale.css'
import {useSpring} from "react-spring";
import {apiClient} from "../api/api";
import {useNavigate} from "react-router-dom";
import ProductList from "../components/ProductList";
import Cart from "../components/Cart";
import Header from "../components/Header";
import ProductTableHeader from "../components/ProductTableHeader";
import { useQuery } from 'react-query';
import {fetchCustomerData, fetchProducts} from "../api/apiFunctions";
const Wholesale = () => {
  const [cart, setCart] = useState(() => {
    return JSON.parse(localStorage.getItem('eko-jazo-cart')) || [];
  }, []);
  const [quantities, setQuantities] = useState({});
  const [note, setNote] = useState("");
  const trigger = useScrollTrigger();
  const [loading, setLoading] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const style = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });


  const { data: products, isLoading: productsLoading, error: productsError } = useQuery('products', fetchProducts);
  const { data: customerData, isLoading: customerDataLoading, error: customerDataError } = useQuery('customerData', fetchCustomerData);

const isAnyLoading = productsLoading || customerDataLoading || loading;

  useEffect(() => {
    localStorage.setItem('eko-jazo-cart', JSON.stringify(cart));
  }, [cart]);

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
  };

  const handleConfirmOrder = async () => {
    setLoading(true);
    const itemsToOrder = cart.map(item => ({
      itemId: item.id,
      quantity: item.quantity
    }));

    const orderDetails = {
      items: itemsToOrder
    };

    try {
      await apiClient.post('/dispatch-note', orderDetails);
      setCart([]);
      localStorage.removeItem('eko-jazo-cart');
      setOrderSuccess(true);
      setTimeout(() => {
        setOrderSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('Order failed:', error);
    } finally {
      setLoading(false);
      setConfirmOpen(false);
    }
  };

  useEffect(() => {
    document.body.classList.add("darken-background");
    document.body.style.background = "url(/Admin_login_with_logo-light.png) no-repeat center center fixed";
    document.body.style.backgroundSize = "cover";
  }, []);

  const handleQuantityChange = (id, quantity) => {
    setQuantities({ ...quantities, [id]: quantity });
  };

  const handleLogout = () => {
     apiClient.get('/auth', {}, {type: "wholesale"})
    .then((response) => {
      navigate('/');
    })
    .catch((error) => {
      console.error(error);
    });
  }

  const handleAddToCart = (product) => {
    const quantity = quantities[product.id] || 0;
    if (quantity === 0) {
      return;
    }
    const newCart = [...cart];
    const existingProductIndex = newCart.findIndex((p) => p.id === product.id);
    if (existingProductIndex > -1) {
      newCart[existingProductIndex].quantity += quantity;
    } else {
      newCart.push({ ...product, quantity });
    }
    setCart(newCart);
    setQuantities({ ...quantities, [product.id]: "" });
  };

  const handleRemoveFromCart = (productID) => {
    const newCart = cart.filter((p) => p.id !== productID);
    setCart(newCart);
  };

  const totalPriceEUR = cart.reduce((total, product) => total + product.priceEUR * product.quantity, 0).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });

  return (
    <Container maxWidth={false} disableGutters sx={{padding: "0 20px"}}>
      <Header
        customerData={customerData}
        handleLogout={handleLogout}
        trigger={trigger}
      />
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <ProductTableHeader />
          {
            productsLoading ? (
              <div>Loading products...</div> // Prikazuje se dok se podaci učitavaju
            ) : productsError ? (
              <div>Error fetching products</div> // Prikazuje se ako dođe do greške
            ) : (
          products.map((product) => (
            <ProductList
              key={product.id}
              product={product}
              onAddToCart={handleAddToCart}
              onQuantityChange={handleQuantityChange}
              quantity={quantities[product.id] || ""}
            />
          )))}
        </Grid>
        <Grid item md={4} xs={12}>
          <Cart
            cart={cart}
            setCart={setCart}
            handleRemoveFromCart={handleRemoveFromCart}
            totalPriceEUR={totalPriceEUR}
            handleNoteChange={(e) => setNote(e.target.value)}
            note={note}
            confirmOpen={confirmOpen}
            handleOpenConfirm={() => setConfirmOpen(true)}
            handleCloseConfirm={handleCloseConfirm}
            handleConfirmOrder={handleConfirmOrder}
            loading={isAnyLoading}
            orderSuccess={orderSuccess}
            style={style}
            setConfirmOpen={setConfirmOpen}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
export default Wholesale;