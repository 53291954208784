import React from 'react';
import { animated } from '@react-spring/web';
import { BounceLoader } from "react-spinners";
import { CheckCircleOutline } from "@mui/icons-material";

function OrderStatusIndicator({ loading, orderSuccess, style }) {
	return (
		<>
			{loading && (
				<div className="overlay">
					<BounceLoader color={"#307AFF"} loading={loading} size={150} />
				</div>
			)}
			{orderSuccess && (
				<animated.div style={style}>
					<div className="success-message">
						<CheckCircleOutline style={{ color: 'green', fontSize: '3rem' }} />
						Narudžba uspješno zaprimljena!
						<p style={{ fontSize: '0.8rem' }}>Primit ćete e-mail potvrde.</p>
					</div>
				</animated.div>
			)}
		</>
	);
}

export default OrderStatusIndicator;
