import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {LoginPage} from './pages/Login';
import Wholesale from "./pages/Wholesale";
import {RequireAuth} from "./routes/AuthProtectedRoutes";
import {QueryClient, QueryClientProvider} from "react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/wholesale" element={
            <RequireAuth>
              <Wholesale />
            </RequireAuth>
          } />
        </Routes>
      </Router>
    </div>
    </QueryClientProvider>
  );
}

export default App;
