import React from 'react';
import { Grid, Card, CardContent, Typography, IconButton, TextField } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

const ProductList = ({ product, onAddToCart, onQuantityChange, quantity }) => {
	return (
		<Card sx={{ margin: "16px 0", backgroundColor: "#F5F7FD", opacity: 0.8 }}>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={2}>
						<Typography variant="h6">#{product.id}</Typography>
					</Grid>
					<Grid item xs={3}>
						<Typography variant="h6">{product.name}</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography variant="h6">{product.measure}</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography variant="h6">
							{product.priceEUR ? parseFloat(product.priceEUR).toLocaleString('hr-HR', { style: 'currency', currency: 'EUR' }) : ""}
						</Typography>
					</Grid>
					<Grid item xs={2}>
						<TextField
							type="number"
							InputProps={{ inputProps: { min: 0 } }}
							value={quantity}
							onChange={(e) => onQuantityChange(product.id, Number(e.target.value))}
						/>
					</Grid>
					<Grid item xs={1}>
						<IconButton onClick={() => onAddToCart(product)}>
							<AddShoppingCartIcon />
						</IconButton>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default ProductList;
