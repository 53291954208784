//axios setup
import axios from 'axios';
import GLOBAL from '../constants/global.js';

export const apiClient = axios.create({
	baseURL: `${GLOBAL.REACT_APP_BACKEND_URL}api/v1/`,
	withCredentials: true,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
});
