import { Button, TextField, Box, Typography } from '@mui/material';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { apiClient } from '../api/api';

export const LoginPage = () => {
  const navigate = useNavigate();
  const [pin, setPin] = useState('');
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await apiClient.post('/auth', { type: "wholesale", pin });
      console.log(response);
      navigate('/wholesale');
    } catch (error) {
      console.error(error);
      setError("Neuspješna prijava. Molimo provjerite vaš PIN.");
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      style={{ backgroundImage: `url("/Admin_login_with_logo-light.png")`, backgroundSize: 'cover' }}
    >
      <Box
        component="form"
        onSubmit={handleLogin}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor="rgba(255,255,255,0.7)"
        p={3}
        borderRadius="16px"
      >
        <Typography variant="h4" component="h1" gutterBottom style={{ fontFamily: 'Roboto Slab, serif', color: '#3f51b5' }}>
          Veleprodajni kupac
        </Typography>
        <TextField
          type="password"
          label="Enter PIN"
          value={pin}
          onChange={(e) => setPin(e.target.value)}
          error={!!error}
          helperText={error}
          onKeyPress={(e) => e.key === 'Enter' && handleLogin(e)}
        />
        <Button type="submit" variant="contained" color="primary">
          Login
        </Button>
      </Box>
    </Box>
  );
};
