import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {apiClient} from "../api/api";

export const RequireAuth = ({ children }) => {
	const navigate = useNavigate();

	useEffect(() => {
		const fetchUser = async () => {
			try {
				await apiClient.get('auth/me', { withCredentials: true });
				// Ako je zahtjev uspješan, korisnik je autentificiran, i ne radimo ništa
			} catch (error) {
				// Ako zahtjev ne uspije, preusmjeravamo korisnika na stranicu za prijavu
				navigate('/');
			}
		};

		fetchUser();
	}, [navigate]);

	return children;
};
